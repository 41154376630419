import {openDB, DBSchema, IDBPDatabase, IDBPTransaction} from 'idb'
import {Coord} from './CoordControl'
import {pad} from './App'

const RECORD_CACHE_LIMIT = 10

const DB_VERSION = 3

export function getRecordNumber(userid:number, sessionid:number, recordid:number) {
	return `${pad(userid, 2)}-${sessionid}-${pad(recordid, 3)}`;
}

export interface Record {
	synced:boolean,
	recordid:number,
	activity?:string,
	site?:string,
	structure?:string,
	highway?:string,
	start_date:string,
	end_date?:string,
	accomplishment?:number,
	start?:Coord,
	end?:Coord,
	comments?:string
}

// some of these will be optional right?  Because something needs to be set when not logged in?
// update database so session table only has one of these records
export interface Session {
	username:string,
	userid:number,
	sessionid:number,
	session_guid:string
}

interface NgocWr extends DBSchema {
	recent_saves: {
		key:number,
		value:Record,
		indexes: {
			'by-date': string
		}
	},
	session: {
		key:'session'|'activeRecordId',
		value:Session|number
	}
}

export async function addRecord(record:Record) {
	const db = await openDB<NgocWr>("ngoc_wr", DB_VERSION)
	const store = db.transaction('recent_saves', 'readwrite').objectStore('recent_saves')
	await store.add(record)

	const existingRecords = await store.getAll()
	const targetRemovals = existingRecords.length - RECORD_CACHE_LIMIT
	if (targetRemovals > 0) {
		// keep most recent (highest) recordids
		// only consider deleting records which are already synced
		const syncedRecords = existingRecords.filter(i => i.synced).sort((a, b) => b.recordid - a.recordid)
		const targetRecords = syncedRecords.slice(0, Math.min(targetRemovals, syncedRecords.length))
		await Promise.all(targetRecords.map(i => store.delete(i.recordid)))
	}

	db.close()
}

export async function clearRecords() {
	const db = await openDB<NgocWr>("ngoc_wr", DB_VERSION)
	await db.clear('recent_saves')
}

export async function loadRecords() {
	const db = await openDB<NgocWr>("ngoc_wr", DB_VERSION)
	console.debug('db', db)
	try {
		const records = await db.getAll('recent_saves')
		console.debug('records', records)
		return records
	} catch (err) {
		// this can happen in Edge when no record exists
		return []
	}
}

async function upgradeDb(db:IDBPDatabase<NgocWr>, oldVersion:number, newVersion:number, transaction:IDBPTransaction) {
	const stores = db.objectStoreNames as unknown as DOMStringList
	console.log(`Upgrading database from ${oldVersion} to ${newVersion}.  Existing stores:`, stores)
	if (!stores.contains('recent_saves')) {
		db.createObjectStore('recent_saves', { keyPath: "recordid" })
	}
	if (!stores.contains("session")) {
		db.createObjectStore('session')
	} else {
		console.debug('Loading old session data')
		const rows = await transaction.objectStore('session').getAll()
		console.debug('Session data', rows)
		db.deleteObjectStore('session')
		db.createObjectStore('session')

		const activeRecordId = parseInt(localStorage.getItem('activeRecordId') || "1")
		localStorage.clear()
		await transaction.objectStore('session').put(activeRecordId, 'activeRecordId')

		if (rows.length > 0) {
			const session = rows[0]
			await transaction.objectStore('session').put(session, 'session')
		}
	}
}

export function initDb() {
	openDB<NgocWr>('ngoc_wr', DB_VERSION, {
		upgrade: upgradeDb
	})
}

export async function loadSession() {
	const db = await openDB<NgocWr>('ngoc_wr', DB_VERSION)
	const session = await db.get('session', 'session') as Session
	return session
}

export async function setSession(session:Session) {
	const db = await openDB<NgocWr>('ngoc_wr', DB_VERSION)
	await db.put('session', session, 'session')
}

export async function loadActiveRecordId() {
	const db = await openDB<NgocWr>('ngoc_wr', DB_VERSION)
	let id = await db.get('session', 'activeRecordId')
	if (id === undefined) {
		db.put('session', 1, 'activeRecordId')
		return 1
	}
	return id as number
}

export async function setActiveRecordId(id:number) {
	const db = await openDB<NgocWr>('ngoc_wr', DB_VERSION)
	await db.put('session', id, 'activeRecordId')
}

export default {initDb, loadRecords, clearRecords, addRecord, loadSession, setSession, loadActiveRecordId, setActiveRecordId}