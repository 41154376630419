import React from 'react'
import './Searcher.css'

interface SearcherProps<T> {
	name:string,
	filterProperties(i:T):string[],
	heading:JSX.Element,
	getId(i:T):string,
	getRow(i:T):JSX.Element,

	choices:T[],
	updateCallback(selection:T):void
}

interface SearcherState {
	filter:string
}

class Searcher<T> extends React.Component<SearcherProps<T>, SearcherState> {
	constructor(props:SearcherProps<T>) {
		super(props)
		this.state = { filter: "" }
	}
	render() {
		let {filter} = this.state
		let {filterProperties, heading, name, getId, getRow, updateCallback, choices} = this.props
		if (filter) {
			filter = filter.toLowerCase()
			choices = choices.filter(i => filterProperties(i).find(j => j.toLowerCase().indexOf(filter) > -1) != undefined)
		}

		return <section className="finder">
			<div><input type="text" placeholder={`${name} Search`} name="search" autoComplete="off" style={{marginBottom: "1rem"}}
					value={this.state.filter} onChange={e => this.setState({filter: e.target.value})} /></div>
			<table className="info-table">
					<thead>{heading}</thead>
					<tbody>{choices.map(i => <tr key={getId(i)} onClick={() => updateCallback(i)}>{getRow(i)}</tr>)}</tbody>
			</table>
		</section>
	}
}

export default Searcher
