import {RGBColor, rgb} from 'd3-color'
import React from 'react'
import './CoordControl.css'

export interface Coord {
  lat:number,
  lng:number
}

interface CoordControlProps {
  coord?:Coord,
  baseColor?:RGBColor,
  name:string,
  updateCallback?:(coord:Coord) => void
  useCurrentCallback?:() => void
}
interface CoordControlState {
  labelColor:RGBColor,
  inputColor:RGBColor,
  borderColor:RGBColor
}

// need a way to push updates to control with props
// but also allow updating of internal state by other factors
export class CoordControl extends React.Component<CoordControlProps, CoordControlState> {
  constructor(props:CoordControlProps) {
    super(props)
    const baseColor = props.baseColor || rgb(255,255,255)
    this.state = {
      labelColor:  baseColor,
      inputColor: baseColor.brighter(),
      borderColor: baseColor.darker()
    }
  }

  updateLat(lat:string) {
    const {coord, updateCallback} = this.props
    updateCallback && updateCallback({
      lat: parseFloat(lat),
      lng: coord ? coord.lng : 0
    })
  }

  updateLng(lng:string) {
    const {coord, updateCallback} = this.props
    updateCallback && updateCallback({
      lat: coord ? coord.lat : 0,
      lng: parseFloat(lng)
    })
  }

  render() {
    const {name, useCurrentCallback} = this.props
    const {inputColor, labelColor, borderColor} = this.state
    let coord = this.props.coord || {lat: 0, lng: 0}
    const spanStyle = {
      backgroundColor: labelColor.toString(),
      borderColor: borderColor.toString()
    }
    const inputStyle = {
      backgroundColor: inputColor.toString(),
      borderColor: borderColor.toString()
    }
    const [latname, lngname] = [`${name}-lat`, `${name}-lng`]
    return <div className="coord-control">
      <label style={spanStyle} htmlFor={latname} className="lat">Lat</label>
      <input style={inputStyle} type="number" name={latname} className="lat" ref="lat" min="52" max="56" step="0.000001" width="11" value={coord.lat}
        readOnly={!this.props.updateCallback}
        onChange={this.props.updateCallback && (e => this.updateLat(e.target.value))} /><br />
      <label style={{...spanStyle, borderBottomLeftRadius: useCurrentCallback ? 0 : "0.5rem"}} htmlFor={lngname} className="lng">Lng</label>
      <input style={{...inputStyle, borderBottomRightRadius: useCurrentCallback ? 0 : "0.5rem"}}
        type="number" name={lngname} className="lng" ref="lng" min="-130" max="-126" step="0.000001" width="11" value={coord.lng}
        readOnly={!this.props.updateCallback}
        onChange={this.props.updateCallback && (e => this.updateLng(e.target.value))} /><br />
      {useCurrentCallback && <button style={spanStyle} onClick={useCurrentCallback}>Use Current</button>}
    </div>
  }
}

export default CoordControl