import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {initDb} from './IdbManager'

initDb()

// import * as serviceWorker from './serviceWorker'
// serviceWorker.register()
navigator.serviceWorker.register('./sw.js')

ReactDOM.render(<App />, document.getElementById('root'))
