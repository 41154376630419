import React from 'react'
import { AppState } from './App'
import {Coord} from './CoordControl'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"

interface WorkMapProps {
	start?:Coord,
	end?:Coord,
	current?:Coord,
	mapCenter?:Coord,
	updatePoint?:(name:keyof AppState["points"], coord:Coord)=>void
}
interface WorkMapState {
	start?:Coord,
	end?:Coord,
	current?:Coord,
	mapBounds:google.maps.LatLngBounds
}

function cmpCoords(c1?:Coord, c2?:Coord) {
	return c1 && c2 && c1.lat == c2.lat && c1.lng == c2.lng
}

function getCoord(e:google.maps.LatLng) {
	return {
		lat: e.lat(),
		lng: e.lng()
	}
}

class WorkMapInternal extends React.Component<WorkMapProps,WorkMapState> {
	constructor(props:WorkMapProps) {
		super(props)
		this.state = {
			start: props.start,
			end: props.end,
			current: props.current,
			mapBounds: this.getSuggestedBounds()
		}
	}

	SINGLE_POINT_MARGIN = 0.01
	MULTI_POINT_MARGIN = 0.001

	getSuggestedBounds() {
		const {start, end, current} = this.props
		const bounds = new google.maps.LatLngBounds()
		start && bounds.extend(start)
		end && bounds.extend(end)
		if (!start || !end) {
			current && bounds.extend(current)
		}
		const margin = (!start && !end) ? this.SINGLE_POINT_MARGIN : this.MULTI_POINT_MARGIN

		// if all available points are too close together,
		// set the bounds of the map out a little further
		// to give the viewer some context
		const span = bounds.toSpan()
		const center = bounds.getCenter()
		if (span.lat() < margin || span.lng() < margin) {
			bounds.extend({
				lat: center.lat() - margin,
				lng: center.lng() - margin
			})
			bounds.extend({
				lat: center.lat() + margin,
				lng: center.lng() + margin
			})
		}
		return bounds
	}

	boundToPoints() {
		const {updatePoint} = this.props
		const bounds = this.getSuggestedBounds()
		const map = this.refs.map as GoogleMap
		map.fitBounds(bounds)
		this.setState({mapBounds: bounds})
		const center = bounds.getCenter()
		updatePoint && updatePoint("mapCenter", getCoord(center))
	}

	updateMapBounds = () => {
		const {updatePoint} = this.props
		const map = this.refs.map as GoogleMap
		this.setState({mapBounds: map.getBounds()})
		const center = map.getCenter()
		updatePoint && updatePoint("mapCenter", getCoord(center))
	}
	updatePoint(name:keyof AppState["points"], coord:google.maps.LatLng) {
		const {updatePoint} = this.props
		updatePoint && updatePoint(name, getCoord(coord))
		// this.boundToPoints()
	}

	componentDidMount() {
		this.boundToPoints()
	}
	componentDidUpdate() {
		const {start, end} = this.props
		const s = this.state
		// compare props points to last updated points to see if change requiring resize has occurred
		if ((start && start != s.start) || (end && end != s.end)) {
			this.boundToPoints()
			this.setState({start, end})
		}
	}

	mapOptions = {
		styles: [{
			featureType: 'poi',
			stylers: [{visibility: 'off'}]
		}, {
			featureType: 'transit',
			stylers: [{visibility: 'off'}]
		}]
	} as google.maps.MapOptions

	// only reposition map if we have not defined start and end
	render() {
		const {start, end, current, updatePoint} = this.props
		const {mapBounds} = this.state
		return <div>
			<GoogleMap options={this.mapOptions} defaultMapTypeId={google.maps.MapTypeId.HYBRID}
				defaultCenter={this.getSuggestedBounds().getCenter()} defaultZoom={14} ref="map"
				onDragEnd={this.updateMapBounds}>
				{start && mapBounds.contains(start) && <Marker position={start} icon="./clipart/green-pin.svg" zIndex={1} draggable={!!updatePoint} onDragEnd={e => this.updatePoint("start", e.latLng)}>
					<InfoWindowGPS name="Start" coord={start} />
				</Marker>}
				{end && mapBounds.contains(end) && <Marker position={end} icon="./clipart/red-pin.svg" zIndex={1} draggable={!!updatePoint} onDragEnd={e => this.updatePoint("end", e.latLng)}>
					<InfoWindowGPS name="End" coord={end} />
				</Marker>}
				{current && mapBounds.contains(current) && <Marker position={current} icon="./clipart/blue-pin.svg" zIndex={0}>
					<InfoWindowGPS name="Current" coord={current} />
				</Marker>}
			</GoogleMap>
		</div>
	}
}

const InfoWindowGPS = ({name, coord}:{name:string, coord:Coord}) => <InfoWindow><span>
	<b>{name}</b><br />
	{coord.lat.toFixed(6)}<br />
	{coord.lng.toFixed(6)}
</span></InfoWindow>

export const WorkMap = withScriptjs(withGoogleMap(WorkMapInternal))
export default WorkMap
