import React from 'react'
import './App.css'
import credentials from './credentials.json'
import CoordControl, {Coord} from './CoordControl'
import {LoginForm, LoginPositiveResponse} from './LoginForm'
import {color, RGBColor} from 'd3-color'
import {WorkMap} from './WorkMap'
import Searcher from './Searcher'
import History from './History'
import {Activity, Site, Structure, Highway} from './RecordParts'
import IdbManager, {Session, Record} from './IdbManager'

import activities from './data/activities.json'
import highways from './data/highways.json'
import sites from './data/sites.json'
import structures from './data/structures.json'

export function pad(num:number, size:number) {
	let s = num+""
	while (s.length < size) s = "0" + s
	return s
}

export interface AppState {
	online:boolean,

	session?:Session,
	activeRecordId:number,

	mode:string,
	searcher?:JSX.Element,
	recentSaves:Record[],

	activity?:Activity,
	site?:Site,
	structure?:Structure,
	highway?:Highway,
	accomplishment:string,
	start_date:string,
	end_date?:string,
	points: {
		start?:Coord,
		end?:Coord,
		current:Coord,
		mapCenter:Coord
	},
	comments:string,

	gpsTracker?:number
}

const DEFAULT_START = {lat: 54.520967, lng: -128.655605} // terrace yard

class App extends React.Component<{}, AppState> {
	constructor(props:{}) {
		super(props)

		navigator.serviceWorker.addEventListener('message', event => {
			if (event.data.msg === "mark-synced") {
				const recentSaves = [...this.state.recentSaves]
				const target = recentSaves.find(i => i.recordid == event.data.recordid)
				if (target) {
					target.synced = true
					this.setState({recentSaves})
				} else {
					console.error(`Asked to update ${event.data.recordid} but could not find it in the queue`)
				}
			}
		})

		// need to deal with events with no end date, no second coordinate point
		this.state = {
			online: navigator.onLine,
			activeRecordId: 1,
			mode: "work",
			searcher: this.searchers["activity"],
			start_date: new Date().toISOString().substr(0, 10),
			points: {
				current: DEFAULT_START,
				mapCenter: DEFAULT_START
			},
			accomplishment: "",
			comments: "",
			recentSaves: []
		}

		IdbManager.loadRecords().then(recentSaves => this.setState({recentSaves}))
		IdbManager.loadSession().then(session => this.setState({session}))
		IdbManager.loadActiveRecordId().then(activeRecordId => this.setState({activeRecordId}))
	}

	setOnlineState = () => {
		this.setState({online: navigator.onLine})
	}
	componentDidMount() {
		window.addEventListener('online', this.setOnlineState)
		window.addEventListener('offline', this.setOnlineState)
	}
	componentWillUnmount() {
		window.removeEventListener('online', this.setOnlineState)
		window.removeEventListener('offline', this.setOnlineState)
	}

	updatePoint = (name:keyof AppState['points'], coord:Coord) => {
		const points = {...this.state.points}
		points[name] = coord
		this.setState({points})
	}

	resetState = () => {
		const {points} = this.state
		this.setState({
			points: {
				current: points.current,
				mapCenter: points.mapCenter
			},
			activity: undefined,
			site: undefined,
			structure: undefined,
			highway: undefined,
			accomplishment: "",
			start_date: new Date().toISOString().substr(0, 10),
			end_date: undefined,
			searcher: undefined,
			comments: ""
		})
	}

	searchers = {
		"activity": <Searcher<Activity>
			name="Activity" key="Activity"
			getId={i => i.activity_number}
			getRow={i => <>
				<td>{i.activity_number}</td>
				<td className="longtext">{i.activity_name}</td>
				<td>{i.uom}</td>
				<td>{i.maintenance_type[0]}/{i.location_code.toUpperCase()}</td>
			</>}
			heading={<tr><th>Code</th><th className="longtext">Name</th><th>UoM</th><th>Type</th></tr>}
			choices={activities}
			filterProperties={i => [i.activity_number, i.activity_name]}
			updateCallback={activity => this.setState({activity, searcher: undefined})}
		/>,

		"highway": <Searcher<Highway>
			name="Highway" key="Highway"
			getId={i => i.uid}
			getRow={i => <><td>{i.uid}</td><td className="longtext">{i.name}</td></>}
			heading={<tr><th style={{width: "10em"}}>Highway Unique</th><th className="longtext">Name</th></tr>}
			choices={highways}
			filterProperties={i => [i.uid, i.name]}
			updateCallback={highway => {
				this.setState({highway, searcher: undefined})
				this.setStartEnd(highway.coordinates)
			}}
		/>,

		"site": <Searcher<Site>
			name="Site" key="Site"
			getId={i => i.site_number}
			getRow={i => <><td>{i.site_number}</td><td className="longtext">{i.site_name}</td></>}
			heading={<tr><th>Site Code</th><th className="longtext">Site Name</th></tr>}
			choices={sites}
			filterProperties={i => [i.site_number, i.site_name]}
			updateCallback={site => {
				const points = {...this.state.points}
				points.start = {lat: site.coordinates[1], lng: site.coordinates[0]}
				this.setState({site, points, searcher: undefined})}
			}
		/>,

		"structure": <Searcher<Structure>
			name="Structure" key="Structure"
			getId={i => i.structure_number}
			getRow={c => <>
				<td>{c.structure_number}</td>
				<td className="longtext">{c.structure_name}</td>
				<td>{c.structure_type}</td>
			</>}
			heading={<tr><th>Structure Code</th><th className="longtext">Structure Name</th><th>Structure Type</th></tr>}
			choices={structures}
			filterProperties={i => [i.structure_number, i.structure_name, i.structure_type]}
			updateCallback={structure => {
				this.setState({structure, searcher: undefined})
				this.setStartEnd(structure.coordinates)
			}}
		/>
	}

	setStartEnd(coords?:number[][]) {
		if (coords) {
			const points = {...this.state.points}
			const [start, end] = [coords[0], coords[coords.length-1]]
			points.start = {lat: start[1], lng: start[0]}
			points.end = {lat: end[1], lng: end[0]}
			this.setState({points})
		}
	}

	toggleSearcher(searcherName:keyof App["searchers"]) {
		const searcher = this.searchers[searcherName]
		this.setState({
			searcher: this.state.searcher == searcher ? undefined : searcher
		})
	}

	getRecordNumber(recordid:number) {
		const {session} = this.state
		return `${pad(session && session.userid || 1, 2)}-${session && session.sessionid || 1}-${pad(recordid, 3)}`
	}

	saveRecord = async () => {
		const {activeRecordId, comments, activity, site, structure, highway, start_date, end_date, points, accomplishment} = this.state

		const newRecord:Record = {
			synced: false,
			recordid: activeRecordId,
			activity: activity && activity.activity_number,
			site: site && site.site_number,
			structure: structure && structure.structure_number,
			highway: highway && highway.highway_number,
			start_date,
			end_date,
			accomplishment: accomplishment ? parseFloat(accomplishment) : 0,
			start: points.start,
			end: points.end,
			comments: comments
		}

		const recentSaves = [...this.state.recentSaves, newRecord]
		const newId = activeRecordId + 1
		this.resetState()
		this.setState({
			activeRecordId: newId,
			recentSaves
		})
		await IdbManager.addRecord(newRecord)
		await IdbManager.setActiveRecordId(newId)

		const reg = await navigator.serviceWorker.ready
		this.saveWithoutSyncSupport() // this could cause race condition?
		reg.sync.register('save-record')
	}

	async saveWithoutSyncSupport() {
		// if we are offline or have nothing to save, don't try
		if (!navigator.onLine || this.state.recentSaves.filter(i => !i.synced).length == 0) return
		const reg = await navigator.serviceWorker.ready
		// if we have unsaved records and sync isn't supported, manually try saving now
		!reg.sync && navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage("save-record")
	}

	loginFailureCallback(err?:string) {
		alert(err || 'Login failed')
	}

	async loginSuccessCallback({activeRecordId, session}:LoginPositiveResponse) {
		alert("Login successful")
		// delete unsaved records
		await IdbManager.clearRecords()
		this.setState({ mode: "work", activeRecordId, session, recentSaves: [] })
	}

	componentWillUpdate(nextProps:{}, nextState:AppState) {
		const {mode, gpsTracker} = nextState
		mode != nextState.mode && this.saveWithoutSyncSupport() // try to save on every page change

		const needsGps = (mode == "map" || mode == "gps")
		if (needsGps && !gpsTracker) {
			this.setState({
				gpsTracker: navigator.geolocation.watchPosition(position => {
					const current = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					}
					this.setState({
						points: { ...this.state.points, current }
					})
				}, err => {
					console.error(err)
					alert(`Could not load GPS data: ${err}`)
				}, {
					enableHighAccuracy: true,
					maximumAge: 500
				})
			})
		} else if (!needsGps && gpsTracker) {
			navigator.geolocation.clearWatch(gpsTracker)
			this.setState({gpsTracker: undefined})
		}
	}

	SearcherButton = ({name}:{name:keyof App["searchers"]}) => {
		return <button
			onClick={() => this.toggleSearcher(name)}
			style={{textDecoration: this.state.searcher == this.searchers[name] ? 'underline' : 'none'}}
		>{name[0].toUpperCase()+name.substr(1)}</button>
	}

	render() {
		const {activeRecordId, mode, gpsTracker, session, points, recentSaves, activity, site, structure, highway, searcher, start_date, end_date, accomplishment, comments, online} = this.state
		return <main>
			{mode == "map" && <section className="panel" style={{display: "flex", flexDirection: "column"}}>
				<WorkMap
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${credentials["google_maps_api_key"]}&v=3.exp&libraries=geometry,drawing,places`}
					containerElement={<div style={{display: "flex", flexDirection: "column", flex: "1 1 auto"}} />}
					mapElement={<div style={{flex: "1 1 auto"}}/>}
					loadingElement={<p style={{textAlign: "center", fontSize: "3em", margin: "auto"}}>Loading...</p>}
					updatePoint={this.updatePoint}
					start={points.start} end={points.end} current={points.current} mapCenter={points.mapCenter}
				/>

				<div id="map-controls" className="controls">
					<button style={{backgroundColor: "lightgreen"}} onClick={() => this.updatePoint("start", this.state.points.current)}>Start at GPS</button>
					<button style={{backgroundColor: "lightgreen"}} onClick={() => this.updatePoint("start", points.mapCenter)}>Start on Map</button>
					<button style={{backgroundColor: "lightcoral"}} onClick={() => this.updatePoint("end", this.state.points.current)}>End at GPS</button>
					<button style={{backgroundColor: "lightcoral"}} onClick={() => this.updatePoint("end", points.mapCenter)}>End on Map</button>
				</div>
			</section>}
			{mode == "gps" && <section id="gps-panel" className="panel">
				<h2>Start</h2>
				<CoordControl name="Start" baseColor={color("lightgreen") as RGBColor} coord={points.start}
					updateCallback={i => this.updatePoint("start", i)}
					useCurrentCallback={() => this.updatePoint("start", this.state.points.current)} />
				<h2>End</h2>
				<CoordControl name="End" baseColor={color("lightcoral") as RGBColor} coord={points.end}
					updateCallback={i => this.updatePoint("end", i)}
					useCurrentCallback={() => this.updatePoint("end", this.state.points.current)} />
				<h2>Current</h2>
				<CoordControl name="Current" baseColor={color("lightblue") as RGBColor} coord={points.current} />
			</section>}
			{mode == "work" && <section id="work-panel" className="panel" style={{overflowY: "scroll"}}>
				<section style={{maxWidth: "30rem", margin: "1rem auto"}} className="inputs">
					<table>
					<tbody><tr>
						<th style={{width: "8rem"}}><this.SearcherButton name="activity" /></th>
						<td style={{width: "17rem"}}>{activity && `${activity.activity_number}: ${activity.activity_name}`}</td>
					</tr><tr>
						<th><this.SearcherButton name="highway" /></th>
						<td>{highway && `${highway.uid}: ${highway.name}`}</td>
					</tr><tr>
						<th><this.SearcherButton name="site" /></th>
						<td>{site && `${site.site_number}: ${site.site_name}`}</td>
					</tr><tr>
						<th><this.SearcherButton name="structure" /></th>
						<td>{structure && `${structure.structure_number}: ${structure.structure_name}`}</td>
					</tr></tbody>
					</table>

					<label htmlFor="accomplishment">Accomplishment{activity && ` (${activity.uom})`}</label>&nbsp;
					<input type="number" placeholder="0" name="number" step="0.001" value={accomplishment} onChange={e => this.setState({accomplishment: e.target.value})} /><br />

					<label htmlFor="start_date">Start Date</label>&nbsp;
					<input type="date" name="start_date" value={start_date} onChange={e => this.setState({start_date: e.target.value})} /><br />

					<label htmlFor="end_date">End Date</label>&nbsp;
					<input type="date" name="end_date" value={end_date} onChange={e => this.setState({end_date: e.target.value})} />
				</section>
				{searcher}
			</section>}
			
			{mode == "login" && <LoginForm successCallback={this.loginSuccessCallback.bind(this)} failureCallback={this.loginFailureCallback.bind(this)} />}

			{mode == "upload" && <section id="upload-panel" className="panel">
			<p id="id-number">{`WR#: ${this.getRecordNumber(activeRecordId)}`}</p>
			<textarea placeholder="Comments" value={comments} onChange={e => this.setState({comments: e.target.value})} />
			<button onClick={this.saveRecord}>Save Record</button>
			<button onClick={this.resetState}>Delete Record</button>
			{recentSaves.length > 0 && <section id="savelist"><h2>Recent Saves</h2><table className="info-table">
				<thead><tr><th>Sync</th><th>ID</th><th>Activity</th><th>Date</th></tr></thead>
				<tbody>{recentSaves.map(i => <tr key={i.recordid}>
					<td>{i.synced && "✔"}</td>
				<td>{this.getRecordNumber(i.recordid)}</td>
				<td>{i.activity}</td>
				<td>{i.start_date}</td>
				</tr>)}</tbody>
			</table></section>}
			</section>}

			{mode == "history" && <History session={session!} />}

			<nav>
				<this.NavInput name="map" disabled={!online} />
				<this.NavInput name="gps" />
				<this.NavInput name="work" />
				<this.NavInput name="login" disabled={!online} required={!session} />
				<this.NavInput name="upload" disabled={!session} />
				<this.NavInput name="history" disabled={!session || !online} />
			</nav>
		</main>
	}

	NavInput = ({name, disabled = false, required = false}:NavInputProps) => {
		return <input type="image" src={`./clipart/${name}.svg`} disabled={disabled}
			onClick={() => this.setState({mode: name})}
			style={{backgroundColor: this.state.mode == name ? "white" : required ? "#fcc" : "#bbb"}}
		/>
	}
}

interface NavInputProps {
	name:string,
	disabled?:boolean,
	required?:boolean
}

export default App
