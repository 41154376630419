import React from 'react'
import {Session} from './IdbManager'
import WorkMap from './WorkMap'
import { Coord } from './CoordControl'
import credentials from './credentials.json'

interface HistoryProps {
	session:Session
}
interface DbRecord {
	record_number:string,
	activity_id:string|null,
	highway_id:string|null,
	structure_id:string|null,
	start_date:string,
	end_date:string|null,
	accomplishment:number,
	start_lat:number|null,
	start_lng:number|null,
	end_lat:number|null,
	end_lng:number|null,
	comments:string
}

interface HistoryState {
	records?:DbRecord[],
	targetRecord?:DbRecord
}

export default class History extends React.Component<HistoryProps, HistoryState> {
	constructor(props:HistoryProps) {
		super(props)
		this.state = {}
		const {session} = props

		fetch('https://wr.ngoc.ca/load-records.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ session })
		}).then(async response => {
			const responseText = await response.text()
			console.debug("Received", responseText)
			if (!response.ok) {
				console.error("Error loading", response)
				throw response
			}
			const records = JSON.parse(responseText).records as DbRecord[]
			this.setState({
				records: records.sort((a, b) => b.start_date.localeCompare(a.start_date))
			})
		})
	}

	render() {
		const {records, targetRecord} = this.state

		return <section id="history-panel" className="panel">
			<h1>History{targetRecord && ` ${targetRecord.record_number}`}</h1>
			{targetRecord && <>
				<button
					style={{position: 'absolute', right: '4rem', marginTop: '0.5rem', padding: '0 0.5rem', fontSize: '1.6em', zIndex: 1, borderRadius: 0}}
					onClick={() => this.setState({targetRecord: undefined})}
				>✖</button>
				<WorkMap
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${credentials["google_maps_api_key"]}&v=3.exp&libraries=geometry,drawing,places`}
					containerElement={<div style={{display: "flex", position: "absolute", width: "100%", height: "500px"}} />}
					loadingElement={<p style={{textAlign: "center", position: "absolute", width: "100%", lineHeight: "500px", fontSize: "3em", backgroundColor: "white"}}>Loading...</p>}
					mapElement={<div style={{flex: "1 1 auto"}}/>}
					start={targetRecord.start_lat && targetRecord.start_lng ? {lat: targetRecord.start_lat, lng: targetRecord.start_lng} : undefined}
					end={targetRecord.end_lat && targetRecord.end_lng ? {lat: targetRecord.end_lat, lng: targetRecord.end_lng} : undefined}
				/>
			</>}
			{!records && <p>Loading</p>}
			{records && <table className="info-table">
				<thead><tr><th style={{minWidth: "5.5rem"}}>Record #</th><th>Act</th><th style={{minWidth: "4.5rem"}}>Date</th><th>Qty</th><th>Start</th><th>End</th><th>Comments</th></tr></thead>
				<tbody>{records.map(i => <tr key={i.record_number} onClick={() => this.setState({targetRecord: i})} style={{cursor: 'pointer'}}>
					<td>{i.record_number}</td>
					<td>{i.activity_id}</td>
					{/* <td>{i.highway_id}</td> */}
					{/* <td>{i.structure_id}</td> */}
					<td>{formatDate(i.end_date ? i.end_date : i.start_date)}</td>
					{/* <td>{formatDate(i.end_date)}</td> */}
					<td>{i.accomplishment}</td>
					<td style={{textAlign: "right"}}>{formatPosition(i.start_lat, i.start_lng)}</td>
					<td style={{textAlign: "right"}}>{formatPosition(i.end_lat, i.end_lng)}</td>
					<td>{i.comments}</td>
				</tr>)}</tbody>
			</table>}
		</section>
	}
}

function formatDate(date:string|null) {
	if (!date || date.length < 10) return date
	return <>{date.substr(0,4)}<br />{date.substr(5)}</>
}
function formatPosition(lat:number|null, lng:number|null) {
	if (!lat || ! lng) return
	return <>{lat.toFixed(6)}<br />{lng.toFixed(6)}</>
}

// TODO hover/click popup map with points
