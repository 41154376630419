import React from 'react'
import IdbManager, {Session} from './IdbManager'

export interface LoginFormProps {
	successCallback:(responseData:LoginPositiveResponse)=>void,
	failureCallback:(err:string)=>void
}
interface LoginFormState {
	username:string,
	password:string
}

export interface LoginPositiveResponse {
	activeRecordId:number,
	session:Session
}
export interface LoginNegativeResponse {
	reason:string
}
export type LoginResponse = LoginPositiveResponse|LoginNegativeResponse

export class LoginForm extends React.Component<LoginFormProps,LoginFormState> {
	username = React.createRef<HTMLInputElement>()
	password = React.createRef<HTMLInputElement>()

	constructor(props:LoginFormProps) {
		super(props)
		this.state = {
			username: "",
			password: ""
		}
	}

	async login() {
		const {username, password} = this.state

		const records = await IdbManager.loadRecords()
		const unsavedRecords = records.filter(i => !i.synced)
		if (unsavedRecords.length > 0) {
			if (!confirm(`Logging in will discard ${unsavedRecords.length} unsaved records.  Proceed?`)) {
				return;
			}
		}

		try {
			const response = await fetch('https://wr.ngoc.ca/login.php', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({username, password})
			})
			const responseBody = await response.text()
			const responseData = JSON.parse(responseBody) as LoginResponse
			if ('reason' in responseData) {
				throw responseData.reason
			}
			const {activeRecordId, session} = responseData
			await IdbManager.setSession(session)
			await IdbManager.setActiveRecordId(activeRecordId)
			this.props.successCallback(responseData)
		} catch (err) {
			alert(err)
			console.error(err)
			this.props.failureCallback(err)
		}
	}

	render() {
		const {username, password} = this.state
		return <section id="login-panel" className="panel"><form onSubmit={e => { e.preventDefault(); this.login() }}>
			<label htmlFor="username">Username</label><br />
			<input type="text" name="username" placeholder="Username" value={username} onChange={e => this.setState({username: e.target.value})} autoCapitalize="none" autoComplete="username" /><br />
			<label htmlFor="password" style={{marginTop: "1rem"}}>Password</label><br />
			<input type="password" name="password" placeholder="Password" value={password} onChange={e => this.setState({password: e.target.value})} autoComplete="current-password" /><br />
			<button>Login</button>
		</form></section>
	}
}
